/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.9.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var SsoProviderKind;
(function (SsoProviderKind) {
    SsoProviderKind["Google"] = "Google";
    SsoProviderKind["Apple"] = "Apple";
    SsoProviderKind["Azure"] = "Azure";
    SsoProviderKind["Custom"] = "Custom";
})(SsoProviderKind || (SsoProviderKind = {}));
export function SsoProviderKindFromJSON(json) {
    return SsoProviderKindFromJSONTyped(json, false);
}
export function SsoProviderKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SsoProviderKindToJSON(value) {
    return value;
}
