/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.9.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAuthStateFromJSON, ApiAuthStateToJSON, } from './ApiAuthState';
export function AuthStateResponseInternalFromJSON(json) {
    return AuthStateResponseInternalFromJSONTyped(json, false);
}
export function AuthStateResponseInternalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'protocol': json['protocol'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'started': (new Date(json['started'])),
        'state': ApiAuthStateFromJSON(json['state']),
        'identificationString': json['identification_string'],
    };
}
export function AuthStateResponseInternalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'protocol': value.protocol,
        'address': value.address,
        'started': (value.started.toISOString()),
        'state': ApiAuthStateToJSON(value.state),
        'identification_string': value.identificationString,
    };
}
