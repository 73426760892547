/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.9.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { TargetKindFromJSON, TargetKindToJSON, } from './TargetKind';
export function TargetSnapshotFromJSON(json) {
    return TargetSnapshotFromJSONTyped(json, false);
}
export function TargetSnapshotFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'kind': TargetKindFromJSON(json['kind']),
        'externalHost': !exists(json, 'external_host') ? undefined : json['external_host'],
    };
}
export function TargetSnapshotToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'kind': TargetKindToJSON(value.kind),
        'external_host': value.externalHost,
    };
}
